import { createRouter, createWebHashHistory } from 'vue-router';
import CreateTask from '../components/CreateTask.vue';
import ShowTask from '../components/Task.vue';
import HomeView from '../components/Home.vue'; // 假设你有一个主页组件

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'Task distribution' },
  },
  {
    path: '/create/task', // 使用动态路由匹配以便传递书籍 ID
    name: 'CreateTask',
    component: CreateTask,
    props: true, // 允许组件通过 props 接收路由参数
    meta: { title: 'Task distribution' },
  },
  {
    path: '/task/:task', // 根据任务ID 显示
    name: 'ShowTask',
    component: ShowTask,
    props: true,
    meta: { title: 'Task distribution' },
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),// createWebHistory(process.env.BASE_URL),
  routes,
});

// 导航守卫，每次路由变化后设置网页标题
router.afterEach((to, from) => {
  // if (from.meta) {
  //   var a = from.hash;
  // }
  if (to.meta && to.meta.title) {
    document.title = from.meta.title;
    document.title = to.meta.title;
  }
});
export default router;
