<template>
    <transition name="fade">
      <div v-if="visible" class="toast" :class="['toast--' + localPosition, { 'toast--closable': localClosable }]">
        {{ localMessage }}
        <br />
        <button v-if="localClosable" @click="hide">关闭</button>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name:"ShowToast",
    props: {
      message: String,
      duration: { type: Number, default: 5000 },
      closable: { type: Boolean, default: false },
      position: { type: String, default: 'bottom' } // 'center' 或 'bottom'
    },
    data() {
      return {
        localMessage: this.message,
        localClosable: this.closable,
        localPosition: this.position,
        visible: false
      };
    },
    methods: {
      show(message, duration = this.duration, closable = this.closable, position = this.position) {
        this.localMessage = message;
        this.localClosable = closable;
        this.localPosition = position;
        this.visible = true;
        if (!closable && duration > 0) {
          setTimeout(() => {
            this.visible = false;
          }, duration);
        }
      },
      hide() {
        this.visible = false;
      }
    }
  };
  </script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.toast {
  background-color: black;
  color: white;
  padding: 10px 10px 10px; /* Increase bottom padding to accommodate the button */
  border-radius: 10px;
  z-index: 1000;
  position: fixed;
  min-width: 200px;
  text-align: center;
}
.toast--center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast--bottom {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
button {
  /* position: absolute; */
  left: 50%;
  bottom: 10px; /* Position button 10px above the bottom of the toast */
  /* transform: translateX(-50%); */
  border: none;
  background: rgba(255, 255, 255, 0.8);
  color: black;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}
</style>

