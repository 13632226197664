<template>
    <div class="app">
        <div class="form-inputs">
            <input type="text" v-model="taskLink" readonly="true" placeholder="任务链接" />
            <button ref="copyLinkButton" :data-clipboard-text="taskLink">{{ copiedLinkText }}</button>
            <textarea v-model="description" readonly="true" placeholder="任务描述" rows="10"></textarea>
            <button ref="copyDescriptionButton" :data-clipboard-text="description">{{ copiedDescriptionText }}</button>
        </div>
        <div class="images-wrapper">
            <div v-for="(link, index) in imageLinks" :key="index" class="image-container">
                <img :src="link" alt="Image Preview" class="preview" @click="openModal(link)">
                <!-- Download button for each image -->
                <a :href="link" :download="'task-' + taskId + '-' + index + '.png'" class="download-btn">下载图片</a>
            </div>
        </div>

        <br />
        <!-- 分隔符 -->
        <div class="separator">
            <span class="separator-text">评论区域</span>
        </div>
        <!-- 评论区域 -->
        <div class="comments-section">
            <div class="comment" v-for="(comment, index) in comments" :key="index">
                <p><strong>游客:</strong> {{ comment.content }}</p>
                <p>{{ formatTimestamp(comment.create_ts) }}</p>
            </div>
            <textarea v-model="newComment" placeholder="留下您的评论..." rows="8"></textarea>
            <button @click="submitComment">提交评论</button>
        </div>
        <!-- 模态框用于显示大图 -->
        <div v-if="showModal" class="modal" @click="showModal = false">
            <img :src="modalImage" class="modal-content" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import ClipboardJS from 'clipboard';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
    name: 'ShowTask',
    setup() {
        const route = useRoute();
        const imageLinks = ref([]);
        const taskLink = ref('');
        const description = ref('');
        const taskId = ref('');
        const file = ref(null);
        const showModal = ref(false);
        const modalImage = ref('');
        const copiedLinkText = ref("复制链接");
        const copiedDescriptionText = ref("复制描述");
        const comments = ref([]);
        const newComment = ref('');
        let clipboard;
        let clipboard2;
        const copyLinkButton = ref(null);
        const copyDescriptionButton = ref(null);

        // Function to fetch task details
        function fetchTask() {
            const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
            axios.get(apiBaseUrl + `/v1/task/${taskId.value}`)
                .then(response => {
                    console.log(response.data);
                    taskLink.value = response.data.task.task_link;
                    description.value = response.data.task.description;
                    imageLinks.value = response.data.images.map(img => img.image_link); // 假设后端以此格式发送数据
                    comments.value = response.data.comments; // 假设后端以此格式发送评论数据
                })
                .catch(error => {
                    console.error('Error fetching task data:', error);
                });
        }

        // Function to submit a comment
        function submitComment() {
            if (!newComment.value) {
                alert("请填写评论内容.");
                return;
            }

            const formData = new FormData();

            formData.append('taskID', taskId.value);
            formData.append('content', newComment.value);
            const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
            axios.post(apiBaseUrl + '/v1/task/comment', formData)
                .then(response => {
                    // comments.value.push(response.data); // 假设后端返回新添加的评论数据
                    console.log("content:"+response.data.data.content);
                    comments.value = [...comments.value, {
                        'content': response.data.data.content,
                        'create_ts': response.data.data.create_ts
                    }];
                    newComment.value = '';
                })
                .catch(error => {
                    console.error('Failed to submit comment:', error);
                });
        }

        // Function to format timestamp
        function formatTimestamp(timestamp) {
            return dayjs(timestamp).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        }

        // Handle file selection
        function handleFileChange(event) {
            file.value = event.target.files[0];
        }

        // Function to upload the screenshot
        function uploadScreenshot() {
            if (!file.value) {
                alert("Please select a file to upload.");
                return;
            }
            const formData = new FormData();
            formData.append('screenshot', file.value);
            formData.append('taskID', taskId.value);

            axios.post('/v1/task/completion', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    alert('Screenshot uploaded successfully!');
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Failed to upload screenshot:', error);
                });
        }

        function openModal(image) {
            modalImage.value = image;
            showModal.value = true;
        }

        onMounted(async () => {
            taskId.value = route.params.task;
            console.log("taskId:" + taskId.value);
            // Call fetchTask on component mount
            fetchTask();
            await nextTick();
            console.log(copyLinkButton.value); // 查看是否获取到了元素
            console.log(copyDescriptionButton.value); // 查看是否获取到了元素
            clipboard = new ClipboardJS(copyLinkButton.value);

            clipboard.on('success', (e) => {
                if (e.trigger === copyLinkButton.value) {
                    copiedLinkText.value = "已复制";
                }

                setTimeout(() => {
                    if (e.trigger === copyLinkButton.value) {
                        copiedLinkText.value = "复制链接";
                    }
                }, 2000); // Reset after 2 seconds

                e.clearSelection();
            });

            clipboard.on('error', (e) => {
                console.error('Failed to copy:', e);
            });

            clipboard2 = new ClipboardJS(copyDescriptionButton.value);

            clipboard2.on('success', (e) => {
                if (e.trigger === copyDescriptionButton.value) {
                    copiedDescriptionText.value = "已复制";
                }

                setTimeout(() => {
                    if (e.trigger === copyDescriptionButton.value) {
                        copiedDescriptionText.value = "复制描述";
                    }
                }, 2000); // Reset after 2 seconds

                e.clearSelection();
            });

            clipboard2.on('error', (e) => {
                console.error('Failed to copy:', e);
            });
        });

        onUnmounted(() => {
            clipboard.destroy();
            clipboard2.destroy();
        });

        return {
            imageLinks,
            taskLink,
            taskId,
            description,
            showModal,
            modalImage,
            handleFileChange,
            uploadScreenshot,
            openModal,
            copiedLinkText,
            copiedDescriptionText,
            copyLinkButton,
            copyDescriptionButton,
            submitComment,
            comments,
            newComment,
            formatTimestamp
        };
    },
}
</script>

<style>
.upload-area {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    margin: 20px;
    min-height: 150px;
}

.images-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.preview {
    width: 80px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
}

.preview:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.download-btn {
    padding: 8px 16px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.download-btn:hover {
    background-color: #0056b3;
}

.form-inputs input {
    padding: 10px;
    width: 90%;
    /* margin: auto; */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.form-inputs textarea {
    /* margin: 10px; */
    padding: 10px;
    width: 90%;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.separator {
    position: relative;
    text-align: center;
    border-top: 1px solid #ccc;
    line-height: 0.1em;
    margin: 20px 0;
}

.separator-text {
    background: #fff;
    padding: 0 10px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-width: 90%;
    max-height: 80%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.copied {
    background-color: #28a745;
}

.button {
    padding: 6px 12px;
    margin-left: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}

.comments-section {
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment:last-child {
    border-bottom: none;
}

textarea {
    width: 90%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

textarea:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.comments-section {
    width: 80%;
    margin: 20px auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    text-align: left;
}

.comment p {
    margin: 5px 0;
    text-align: left;
}
</style>
