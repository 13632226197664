<template>
  <div class="app">
    <div class="upload-area" @dragover.prevent @drop="handleDrop">
      拖动图片到这里上传
    </div>
    <!-- 图片预览区域 -->
    <div v-for="(link, index) in imageLinks" :key="index" class="image-container">
      <img :src="link" alt="Image Preview" class="preview" @click="openModal(link)">
      <button @click="removeImage(index)" class="delete-btn">×</button>
    </div>
    <div class="form-inputs">
      <input type="text" v-model="taskLink" placeholder="输入任务链接" />
      <textarea v-model="description" placeholder="输入任务描述" rows="4"></textarea>
      <select v-model="deadline">
        <option disabled value="">请选择任务时效</option>
        <option v-for="option in options" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select>
    </div>
    <button @click="submitData">提交</button>
    <div v-if="isLoading">正在提交...</div>
    <div v-if="successMessage">{{ successMessage }} 查看任务：<a :href="taskUrl">{{ taskUrl }}</a></div>
    <Toast ref="toastRef" />
    <!-- 模态框用于显示大图 -->
    <div v-if="showModal" class="modal" @click="showModal = false">
            <img :src="modalImage" class="modal-content" />
        </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import Toast from './Toast.vue';

const imageLinks = ref([]);
const taskLink = ref('');
const description = ref('');
const deadline = ref('0');
const files = ref([]);
const isLoading = ref(false);
const taskUrl = ref('');
const successMessage = ref('');
const showModal = ref(false);
const modalImage = ref('');

const toastRef = ref(null);

const options = [
  { value: '0', text: '30分钟'},
  { value: '1', text: '1小时' },
  { value: '3', text: '3小时' },
  { value: '6', text: '6小时' },
  { value: '12', text: '12小时' },
  { value: '24', text: '24小时' }
];

const handleDrop = (event) => {
  event.preventDefault();
  const droppedFiles = event.dataTransfer.files;
  Array.from(droppedFiles).forEach(file => {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imageLinks.value.push(e.target.result);
        files.value.push(file);
      };
      reader.readAsDataURL(file);
    } else {
      alert('请上传图片文件！');
    }
  });
};

const removeImage = (index) => {
  imageLinks.value.splice(index, 1);
  files.value.splice(index, 1);
};
const openModal = (image) => {
            modalImage.value = image;
            showModal.value = true;
        };
const submitData = async () => {

  // if (taskLink.value.trim() === '') {
  //   toastRef.value.show('请输入任务链接', 5000, false,'center');
  //   return;
  // }

  if (deadline.value === '') {
    toastRef.value.show('请选择任务时效', 5000, true,'center');
    return;
  }

  isLoading.value = true;  // 开始加载
  const formData = new FormData();
  files.value.forEach(file => {
    formData.append('images', file);
  });
  formData.append('description', description.value);
  formData.append('taskLink', taskLink.value);
  formData.append('deadline', deadline.value);

  try {
    const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    const response = await axios.post(apiBaseUrl + '/v1/create/task', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log(response.data);
    if (response.data.status === 1) {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      taskUrl.value = apiBaseUrl + response.data.task_url;
      successMessage.value = '任务提交成功！';
    }
    isLoading.value = false;  // 加载结束
  } catch (error) {
    console.error('Upload error:', error);
    isLoading.value = false;  // 加载结束
  }
};
</script>

<style>
.upload-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin: 20px;
  min-height: 150px;
}

.image-container {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.preview {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.delete-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 0 5px;
  cursor: pointer;
}

.form-inputs input,
.form-inputs select {
  margin: 10px;
  padding: 10px;
  width: 90%;
}

.form-inputs textarea {
  display: block;
  width: 90%;
  margin: 10px auto;
  padding: 10px;
}

button {
  padding: 10px 20px;
  margin: 10px;
  background: #f8f9fa;
  border: 1px solid #ccc;
  cursor: pointer;
}

.app {
  margin-bottom: 60px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-width: 90%;
    max-height: 80%;
}
</style>
